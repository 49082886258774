import React, { useState } from 'react';

import Jumbotron from 'react-bootstrap/Jumbotron';
import Toast from 'react-bootstrap/Toast';
import { Container, Button, Modal, Form } from 'react-bootstrap';

import './App.css';

const ExampleToast = ({ children }) => {
  const [show, toggleShow] = useState(true);

  return (
    <>
      {!show && <Button onClick={() => toggleShow(true)}>Show Toast</Button>}
      <Toast show={show} onClose={() => toggleShow(false)}>
        <Toast.Header>
          <strong className="mr-auto">React-Bootstrap</strong>
        </Toast.Header>
        <Toast.Body>{children}</Toast.Body>
      </Toast>
    </>
  );
};

const App = () => (
  <Container className="p-3">
    <Jumbotron>
      <Modal.Dialog>
        <Modal.Header>
          <Modal.Title>LaCarta.app</Modal.Title>
        </Modal.Header>

        <Form>
          <Modal.Body>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Código de restaurante (6 dígitos)</Form.Label>
              <Form.Control type="email" placeholder="Código de restaurante" />
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Contraseña</Form.Label>
              <Form.Control type="password" placeholder="Contraseña" />
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="primary">Acceder</Button>
          </Modal.Footer>
        </Form>
      </Modal.Dialog>
    </Jumbotron>
  </Container>
);

export default App;
